<template>
<div>
  <v-app-bar
      color="deep-purple accent-4"
      :fixed="true"
      dense
      dark>
    <div style="margin-right: 15px;" @click="back">
      <v-icon>mdi-chevron-left</v-icon>
    </div>
    <v-toolbar-title>Signature Pad</v-toolbar-title>
  </v-app-bar>

  <div
    style="height: calc(100% - 50px - 56px); overflow-y: auto; margin-top: 48px; margin-bottom: 56px; width: 100%;">

  <div style="padding: 8px;"></div>

  <div style="display: -webkit-flex; display: flex; flex-direction: row; justify-content: center;">

  <div id="signature-pad-1" class="signature-pad" :style="sp_style">
    <div style="color: #6200ea;">
      signature name <input type="text" id="signatureName" v-model="signatureName" style="width: 100%;border: 1px solid #6200ea;padding: 10px;">
    </div>
    <br/>
    <br/>
    <div class="signature-pad--body">
      <canvas></canvas>
    </div>
    <div class="signature-pad--footer">
      <div class="signature-pad--actions">
        <div>
          <button type="button" class="button clear" data-action="clear" style="margin-right: 10px;border: 1px solid #6200ea;background: #6200ea;color: white;padding: 10px;border-radius: 4px;" @click="s_p_clear">Clear</button>
          <button type="button" class="button" data-action="undo" @click="s_p_undo" style="border: 1px solid #6200ea;
          background: #6200ea;
          color: white;
          padding: 10px;
          border-radius: 4px;">Undo</button>
        </div>
        <div>
          <button type="button" class="button save" data-action="save-png" style="display: none;">Confirm</button>
          <button type="button" class="button save" data-action="save-jpg" :disabled="save_btn_disable" @click="s_p_save_jpg"
          style="border: 1px solid #6200ea;background: #6200ea;color: white;padding: 10px;border-radius: 4px;">Confirm</button>
          <button type="button" class="button save" data-action="save-svg" style="display: none;">Confirm</button>
        </div>
      </div>
    </div>
  </div>

  </div>

  <div style="padding: 10px;"></div>

  </div>

  <v-snackbar
    v-model="snackbar"
    :timeout="snackbar_timeout"
  >
    {{ snackbar_txt }}
    <v-btn
      style="display: none;"
      color="pink"
      text
      @click="snackbar = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import { post } from '@/utils/request.js'
import {EH_MOBILE_GLOBAL, EH_MOBILE_FUN} from '@/helper.js'
import {setSignaturePad} from '@/vendor/signature_pad.umd.js'
import '@/vendor/signature-pad.css'

export default {
  data () {
    return {
      snackbar: false,
      snackbar_txt: '',
      snackbar_timeout: 1500,

      show_no_data: false,

      tab_select: 0,

      signatureName : '',

      // page: 1,
      tms_route_id: null,
      tms_order_id: null,
      tms_order_task_id: null,

      signaturePad: null,
      sp_style: {
        height: '450px'
      },
      save_btn_disable: false
    }
  },
  watch: {
    tab_select (newval, oldval) {
      console.log([newval, oldval])
    },
  },
  methods: {
    goSetSignaturePad: function () {
      this.signaturePad = setSignaturePad('signature-pad-1')
    },
    back: function () {
      this.$router.go(-1)
    },
    s_p_clear () {
      this.signaturePad.clear();
    },
    s_p_undo () {
      var data = this.signaturePad.toData();

      if (data) {
        data.pop(); // remove the last dot or line
        this.signaturePad.fromData(data);
      }
    },
    s_p_save_jpg () {
      if (this.signaturePad.isEmpty() || this.signatureName == '') {
        this.snackbar_txt = "Please provide a signature first."
        this.snackbar = true
        return
      } else {
        var dataURL = this.signaturePad.toDataURL("image/jpeg");
        // console.log(dataURL)
        // upload(dataURL, "signature.jpg");
        this.save_btn_disable = true

        let url = EH_MOBILE_GLOBAL.save_signature_img
        let data = EH_MOBILE_FUN.serialize({
          'base64jpeg': dataURL,
          'tms_route_id': this.tms_route_id,
          'tms_order_id': this.tms_order_id,
          'tms_order_task_id': this.tms_order_task_id,
          'signature_name' : this.signatureName
        })
        post(url, data).then((response) => {
          this.save_btn_disable = false
          if (response.data.status !== 'success') {
            this.snackbar_txt = "Save Signature Failed, Please Try Again."
            this.snackbar = true
            console.log(response)
            return
          }
          if (response.data.status === 'success') {
            this.snackbar_txt = "Saved"
            this.snackbar = true
            setTimeout(() => {
              this.back()
            }, 1500)
            return;
          }
        })
      }
    }
  },
  created () {
    this.tms_route_id = this.$route.params.tms_route_id
    this.tms_order_id = this.$route.params.tms_order_id
    this.tms_order_task_id = this.$route.params.tms_order_task_id

    this.sp_style.height = (window.innerHeight - 56 - 48 - 30) + 'px'
  },
  mounted () {
    this.goSetSignaturePad()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.signature-pad {
  width: 96%;
}
.signature-pad--body canvas {
  width: 100%;
}
</style>
